import { useState, useEffect } from 'react'

export default function useLocalStorageState(
  key,
  defaultValue = '',
  { serialize = JSON.stringify, deserialize = JSON.parse } = {},
) {
  const isWindowDefined = typeof window !== 'undefined'
  const [state, setState] = useState(() => {
    if (isWindowDefined) {
      const valueInLocationStorage = window.localStorage.getItem(key)
      if (valueInLocationStorage) {
        return deserialize(valueInLocationStorage)
      }
    }
    return typeof defaultValue === 'function' ? defaultValue() : defaultValue
  })
  useEffect(() => {
    if (isWindowDefined) window.localStorage.setItem(key, serialize(state))
  }, [key, serialize, state, isWindowDefined])

  return [state, setState]
}
