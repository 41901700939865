import React, { useState } from 'react'
import useLocalStorageState from '../customhooks/useLocalStorageState'

export const hbfContext = React.createContext()

const Provider = (props) => {
  const [zipCode, setZipCode] = useLocalStorageState('zipCode', '')
  const [activeStep, setActiveStep] = useState('offers')
  const [locationUrl, setLocationUrl] = useLocalStorageState('locationUrl', '/')
  const [phone, setPhone] = useLocalStorageState('phone', '866-375-3631')
  const [selectedOffers, setSelectedOffers] = useState([])
  const [selectedDate, setSelectedDate] = useState({})
  const [selectedPreference, setSelectedPreference] = useState('morning')
  const [availableDates, setAvailableDates] = useState([])
  const [unAvailableDates, setUnAvailableDates] = useState([])
  const [topThreeReviews, setTopThreeReviews] = useState([])
  const [totalReviewCount, setTotalReviewCount] = useState(0)
  const [averageRating, setAverageRating] = useState(5.0)
  const [orderSummary, setOrderSummary] = useState({})
  const [showModal, setShowModal] = useState(false)
  const [successUrl, setSuccessUrl] = useState(null)

  return (
    <hbfContext.Provider
      value={{
        zipCode,
        showModal,
        successUrl,
        locationUrl,
        phone,
        selectedOffers,
        selectedDate,
        selectedPreference,
        activeStep,
        availableDates,
        unAvailableDates,
        averageRating,
        topThreeReviews,
        totalReviewCount,
        orderSummary,
        changeShowModal: (value) => setShowModal(value),
        changeSuccessUrl: (value) => setSuccessUrl(value),
        changeOrderSummary: (value) => setOrderSummary(value),
        changeAvailableDates: (value) => setAvailableDates(value),
        changeUnAvailableDates: (value) => setUnAvailableDates(value),
        changeActiveStep: (value) => setActiveStep(value),
        changeSelectedDate: (value) => setSelectedDate(value),
        changeSelectedPreference: (value) => setSelectedPreference(value),
        changeZipCode: (value) => setZipCode(value),
        changePhone: (value) => setPhone(value),
        changeLocationUrl: (value) => setLocationUrl(value),
        addSelectedOffer: (offer) =>
          setSelectedOffers((currentOffers) => [...currentOffers, offer]),
        removeSelectedOffer: (featureId) =>
          setSelectedOffers(
            selectedOffers.filter((item) => {
              return item.featureId !== featureId
            }),
          ),
        updateSelectedOfferQuantity: (updatedSelectedOffers) =>
          setSelectedOffers(updatedSelectedOffers),
        removeAllSelectedOffers: () => setSelectedOffers([]),
        changeTopThreeReviews: (value) => setTopThreeReviews(value),
        changeAverageRating: (value) => setAverageRating(value),
        changeTotalReviewCount: (value) => setTotalReviewCount(value),
      }}
    >
      {props.children}
    </hbfContext.Provider>
  )
}
const GarageProvider = ({ element }) => <Provider>{element}</Provider>
export default GarageProvider
